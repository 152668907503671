import {
  CommandBar,
  ConstrainMode,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  IContextualMenuItem,
  IContextualMenuProps,
  IObjectWithKey,
  PrimaryButton,
  ScrollablePane,
  ScrollbarVisibility,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Sticky,
  StickyPositionType
} from "@fluentui/react";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { useGetProfile } from "../../api/react-query";

import { FiltersTypeEnum } from "./enums";
import { HeaderItemCount } from "./HeaderItemCount";
import { ListDataFilters } from "./ListDataFilters";
import { ListDataFiltersMassAction } from "./ListDataFiltersMassAction/list-data-filters-mass-action";
import { classNames, listStyles } from "./styles";
import {
  FiltersProps,
  ItemsWithPagination,
  ListDataMassActionsTypes
} from "./types";

import undrawNoData from "@/assets/images/undraw_no_data_qbuo.svg";
import { PermissionsAction } from "@/common/types/permissions";
import { useDebounce } from "@/hooks/index";
import { useConfirmModal } from "@/hooks/useConfirmModal";
import { useURLFilter } from "@/hooks/useUrlFIlter";
import { filterObjectByArray } from "@/utils/FilterObjectByArray";
import { findModuleByLocation } from "@/utils/findModuleByLocation";
import { normalizeString } from "@/utils/NormalizeString";

const _buildColumns = (columns, menuProps, selectItem) => {
  if (columns) {
    columns.forEach(column => {
      if (column.key === "actions") {
        column.onRender = item => (
          <IconButton
            id={`actions-${item.id}`}
            onMenuClick={() => {
              selectItem(item);
            }}
            menuProps={menuProps}
            iconProps={{ iconName: "MoreVertical" }}
            onRenderMenuIcon={() => null}
          />
        );
      }
    });
  }

  return columns;
};

interface ListDataCommandBarCommandBarProps {
  cfg: [] | ItemsWithPagination;
  action?: { text: string; action: () => any };
  _paginate?: any;
  isPaginateDisabled?: boolean;
  hasFilters: boolean;
  toggleFilterVisibility: () => void;
  resetSelectedItems?: () => void;
  resetItemsPerPage?: () => void;
}

interface CommandBarConfigs {
  items: ICommandBarItemProps[];
  farItems: ICommandBarItemProps[];
}

function ListDataCommandBar({
  cfg,
  action,
  _paginate,
  isPaginateDisabled,
  resetSelectedItems,
  resetItemsPerPage,
  hasFilters,
  toggleFilterVisibility
}: ListDataCommandBarCommandBarProps): JSX.Element {
  const [componentConfig, setComponentConfig] = useState<CommandBarConfigs>({
    items: [],
    farItems: []
  });
  const location = useLocation();
  const { setUrlParams } = useURLFilter();

  useEffect(() => {
    const commandBarConfig: CommandBarConfigs = {
      items: [],
      farItems: []
    };

    if (!Array.isArray(cfg)) {
      if (cfg.meta && !isPaginateDisabled) {
        commandBarConfig.items = [
          {
            key: "back",
            text: "Anterior",
            disabled:
              Number(cfg.meta.currentPage) === 1 ||
              Number(cfg.meta.totalItems) === 0,
            iconProps: { iconName: "Back" },
            onClick: () =>
              _paginate({
                page: Number(cfg.meta.currentPage) - 1
              })
          },
          {
            key: "next",
            text: "Próxima",
            iconProps: { iconName: "Forward" },
            disabled:
              Number(cfg.meta.currentPage) === cfg.meta.totalPages ||
              Number(cfg.meta.totalItems) === 0,
            onClick: () =>
              _paginate({
                page: Number(cfg.meta.currentPage) + 1
              })
          }
        ];
      }
    }

    if (hasFilters) {
      commandBarConfig.farItems.unshift({
        key: "cleanFilters",
        text: "Limpar Filtros",
        ariaLabel: "Limpar Filtros",
        iconOnly: true,
        iconProps: { iconName: "ClearFilter" },
        onClick: () => {
          setUrlParams({ pathname: location.pathname, params: {} });
          resetSelectedItems();
          resetItemsPerPage();
        }
      });
      commandBarConfig.farItems.unshift({
        key: "filter",
        text: "Filtros",
        // This needs an ariaLabel since it's icon-only
        ariaLabel: "Filtros",
        iconOnly: true,
        iconProps: { iconName: "Filter" },
        onClick: toggleFilterVisibility
      });
    }

    setComponentConfig(commandBarConfig);
    //eslint-disable-next-line
  }, [cfg]);

  return (
    <CommandBar
      items={componentConfig.items}
      farItems={componentConfig.farItems}
      styles={{
        root: {
          padding: 0
        }
      }}
    />
  );
}
type MassActionOptions = {
  massActionsType?: ListDataMassActionsTypes;
  setOpenMassActionsFilter?: React.Dispatch<React.SetStateAction<boolean>>;
  openMassActionsFilter?: boolean;
  buttonMassActionsText?: string;
  buttonMassActionsIconName?: string;
  sendMassActionsFunction?: (
    params:
      | any
      | {
          updatedFields: { [key: string]: any };
          byFilter: boolean;
          selectedKeys: any;
          debouncedTerms: any;
          itemsSelected: any;
        }
  ) => Promise<
    | {
        response: {
          ok: boolean;
        };
        data: any;
        error?: undefined;
      }
    | {
        response: {
          ok: boolean;
        };
        error: {
          message: any;
          status: any;
          statusText: any;
        };
        data?: undefined;
      }
    | any
  >;
};
export interface ListDataProps {
  onComponentRender?: () => any;
  isLoadingList: boolean;
  items: any | [] | ItemsWithPagination;
  columns: Array<IColumn>;
  menuItems: Array<IContextualMenuItem>;
  setSelectedItem?: (selectedItem) => void;
  massActionsOptions?: MassActionOptions;
  isCheckSelection?: boolean;
  canBeMultipleSelection?: boolean;
  isRowSelectable?: boolean;
  selectionPreservedOnEmptyClick?: boolean;
  selectionMode?: SelectionMode;
  headerAction?: () => any;
  headerText?: string;
  businessUnitsSelector?: any;
  itemsPerPage?: string;
  _paginate?: any;
  filters?: FiltersProps[];
  headerFilterQueryName?: string;
  headerFilters?: {
    text: string;
    queryValue: string;
    count: string;
    iconName?: string;
  }[];
  hasFilters?: boolean;
  isItemsPerPageFilterDisabled?: boolean;
  isCloseFilterButtonDisabled?: boolean;
  isPaginateDisabled?: boolean;
  isDefaultFilterVisible?: boolean;
  skipViewportMeasures?: boolean;
  showTagList?: boolean;
  customSelection?: Selection<IObjectWithKey>;
  hasScrollablePane?: boolean;
  /** Default value is 60vh */
  scrollablePanelHeight?: string;
  layoutMode?:
    | DetailsListLayoutMode.fixedColumns
    | DetailsListLayoutMode.justified;
  massCreateActionOptions?: MassCreateActionOptions;
}

interface MassCreateActionOptions {
  modalTitle: string;
  modalDescription: string;
  modalExecuteFunction: () => void;
}

const selectedKeysRangeTypes = new Set([
  FiltersTypeEnum.CURRENT_ITEMS_ONLY,
  FiltersTypeEnum.DATE,
  FiltersTypeEnum.DATE_RANGE,
  FiltersTypeEnum.SELECT
]);

export function ListData(props: ListDataProps): JSX.Element {
  const {
    items,
    isLoadingList,
    headerAction,
    headerText,
    businessUnitsSelector,
    _paginate,
    skipViewportMeasures = false,
    filters,
    hasFilters,
    headerFilters,
    headerFilterQueryName,
    isDefaultFilterVisible,
    isCheckSelection,
    isRowSelectable,
    isItemsPerPageFilterDisabled,
    isPaginateDisabled,
    isCloseFilterButtonDisabled,
    selectionPreservedOnEmptyClick = false,
    customSelection,
    hasScrollablePane,
    scrollablePanelHeight = "60vh",
    layoutMode,
    canBeMultipleSelection,
    onComponentRender,
    massActionsOptions,
    massCreateActionOptions
  } = props;
  const {
    massActionsType,
    setOpenMassActionsFilter,
    openMassActionsFilter,
    sendMassActionsFunction,
    buttonMassActionsIconName
  } = massActionsOptions ?? {
    massActionsType: null,
    setOpenMassActionsFilter: null,
    openMassActionsFilter: false,
    sendMassActionsFunction: null,
    buttonMassActionsIconName: null
  };

  const { urlParams, setUrlParams } = useURLFilter();
  const selectedTermsEnabledToFilter = filters
    ? filters
        ?.filter(f => !selectedKeysRangeTypes.has(f.type as FiltersTypeEnum))
        ?.map(f => f.filterQuery)
        .flat()
    : [];

  const selectedKeysEnabledToFilter = filters
    ? filters
        .filter(f => selectedKeysRangeTypes.has(f.type as FiltersTypeEnum))
        ?.map(f => f.filterQuery)
        .flat()
    : [];

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [allCurrentItems, setAllCurrentItems] = useState<any>([]);
  const [itemsSelected, setItemsSelected] = useState<Array<any>>([]);

  const [selectedKeys, setSelectedKeys] = useState<{ [x: string]: string }>(
    filterObjectByArray(urlParams?.params, selectedKeysEnabledToFilter)
  );

  const [selectedTerms, setSelectedTerms] = useState<{
    [x: string]: string;
  }>(filterObjectByArray(urlParams?.params, selectedTermsEnabledToFilter));
  const [visibleTerms, setVisibleTerms] = useState<{
    [x: string]: string;
  }>(filterObjectByArray(urlParams?.params, selectedTermsEnabledToFilter));
  const [bulkOptionsFilters, setBulkOptionsFilters] = useState({});
  const location = useLocation();

  const [selectionMode] = useState<SelectionMode>(
    props.selectionMode || SelectionMode.none
  );
  const [listDataSelectionListner, setListDataSelectionListner] =
    useState<Array<any>>();

  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(
    isDefaultFilterVisible ?? false
  );

  const debouncedTerms = useDebounce(selectedTerms, 500);
  const initialRender = useRef(true);

  const { current: selection } = useRef<Selection<IObjectWithKey>>(
    new Selection({
      onSelectionChanged: () => {
        if (props.setSelectedItem && isCheckSelection) {
          const item = selection.getSelection();
          setListDataSelectionListner(item);
        }
      }
    })
  );

  const userProfile = useGetProfile();
  const userPermissions =
    userProfile?.data?.data?.role?.permissions?.pos?.terms;

  const hasCreateBulkTerms = userPermissions.includes(
    PermissionsAction.CREATE_BULK
  );

  useEffect(() => {
    if (listDataSelectionListner) {
      if (listDataSelectionListner.length > 0) {
        if (
          selectionMode === SelectionMode.multiple ||
          canBeMultipleSelection
        ) {
          let itemArr = listDataSelectionListner;

          for (const is of itemsSelected.filter(is => !!is)) {
            const includesInAllCurrent = items?.items?.find(
              i => i.id === is.id
            );
            const includesInItensSelected = itemArr.find(i => i?.id === is?.id);

            if (includesInAllCurrent) {
              if (!includesInItensSelected) {
                itemArr = itemArr.filter(i => i.id !== is.id);
              }
            } else {
              !includesInItensSelected && is && itemArr.push(is);
            }
          }

          setItemsSelected(itemArr);
        } else {
          props.setSelectedItem(listDataSelectionListner[0]);
        }
      } else {
        if (
          allCurrentItems?.items &&
          itemsSelected &&
          itemsSelected[0] &&
          allCurrentItems?.items[0]
        ) {
          const itemsUnselected = itemsSelected?.filter(i =>
            allCurrentItems?.items?.find(it => it.id === i.id) ? false : true
          );

          props.setSelectedItem(undefined);

          setItemsSelected(itemsUnselected);
        }
      }
    }
  }, [listDataSelectionListner]);

  useEffect(() => {
    if (
      !isLoadingList &&
      allCurrentItems?.items &&
      allCurrentItems?.items[0] &&
      itemsSelected &&
      itemsSelected[0]
    ) {
      itemsSelected.forEach(is => {
        allCurrentItems?.items.find(i => i?.id === is?.id) &&
          selection.setIndexSelected(
            allCurrentItems?.items.findIndex(i => i?.id === is?.id),
            true,
            true
          );
      });
    }
  }, [isLoadingList, itemsSelected, allCurrentItems, selection]);

  useEffect(() => {
    if (_paginate) {
      if (initialRender.current) {
        initialRender.current = false;
      } else {
        const filters = { ...debouncedTerms };

        for (const key in filters) {
          if (typeof filters[key] === "string") {
            filters[key] = filters[key].trim();
          }
        }

        _paginate({
          limit: itemsPerPage ?? 10,
          ...selectedKeys,
          ...filters,
          ...bulkOptionsFilters
        });
      }
    }
  }, [debouncedTerms, bulkOptionsFilters]);

  useEffect(() => {
    setAllCurrentItems(items);
  }, [items]);

  function filterCurrentItems(value, key) {
    if (Array.isArray(allCurrentItems)) {
      setAllCurrentItems(
        value
          ? items.filter(
              item =>
                normalizeString(item[key].toLowerCase()).indexOf(
                  normalizeString(value.toLowerCase())
                ) > -1
            )
          : items
      );
    } else {
      setAllCurrentItems(
        value
          ? {
              ...items,
              items: items.items.filter(
                item =>
                  normalizeString(item[key].toLowerCase()).indexOf(
                    normalizeString(value.toLowerCase())
                  ) > -1
              )
            }
          : items
      );
    }
  }

  const selectItem = useCallback(
    item => {
      if (props.setSelectedItem) props.setSelectedItem(item.id);
    },
    [props.setSelectedItem]
  );

  const toggleOpenMassActionsFilterVisibility = () => {
    setIsFilterVisible && setIsFilterVisible(false);
    setOpenMassActionsFilter(isOpen => !isOpen);
    setBulkOptionsFilters({ isLineStatus: false });
  };

  const toggleFilterVisibility = () => {
    setOpenMassActionsFilter && setOpenMassActionsFilter(false);
    setIsFilterVisible(prevValue => !prevValue);
  };

  const menuProps: IContextualMenuProps = {
    shouldFocusOnMount: true,
    items: props.menuItems
  };

  const columns = _buildColumns(props.columns, menuProps, selectItem);

  const handleHeaderItemLinkClick = currentKeys => {
    setSelectedKeys(currentKeys);
    _paginate({
      limit: itemsPerPage ?? 10,
      ...debouncedTerms,
      ...currentKeys
    });
  };

  useEffect(() => {
    if (findModuleByLocation(location) !== urlParams?.pathname) {
      setSelectedKeys({});
      setSelectedTerms({});
      setVisibleTerms({});
    }
  }, [location.pathname]);

  useEffect(() => {
    onComponentRender && onComponentRender();
  }, []);

  const { openModalWithSettings } = useConfirmModal();

  const openMassCreationModal = () => {
    openModalWithSettings(
      massCreateActionOptions.modalTitle,
      massCreateActionOptions.modalDescription,
      () => massCreateActionOptions.modalExecuteFunction(),
      { primaryButtonText: "Criar em massa" }
    );
  };

  return (
    <Fragment>
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{
          root: {
            paddingTop: 12,
            paddingLeft: 12,
            paddingRight: 12
          }
        }}
      >
        <Stack.Item align="center">
          {!Array.isArray(allCurrentItems) && (
            <>Total: {allCurrentItems?.meta?.totalItems ?? 0}</>
          )}
          {itemsSelected && itemsSelected[0] && (
            <> {`|| Selecionados: ${itemsSelected.length ?? 0}`}</>
          )}
        </Stack.Item>

        <Stack.Item
          align="center"
          style={{
            justifyContent: "center",
            display: "flex"
          }}
        >
          {massActionsType && (
            <PrimaryButton
              iconProps={{ iconName: buttonMassActionsIconName }}
              text="Atualização em Massa"
              onClick={toggleOpenMassActionsFilterVisibility}
              style={{ marginRight: "4px" }}
            />
          )}
          {massCreateActionOptions && hasCreateBulkTerms && (
            <PrimaryButton
              iconProps={{ iconName: "Add" }}
              text="Criar em massa"
              onClick={openMassCreationModal}
              style={{ marginRight: "4px" }}
            />
          )}
          {headerAction && (
            <PrimaryButton
              id={`add-${headerText.replace(/ /g, "-")}`}
              iconProps={{ iconName: "Add" }}
              text={headerText}
              onClick={headerAction}
            />
          )}
        </Stack.Item>
      </Stack>

      {headerFilters && (
        <Stack horizontalAlign="center">
          <HeaderItemCount
            selectedKeys={selectedKeys}
            handleLinkClick={handleHeaderItemLinkClick}
            filterQuery={headerFilterQueryName}
            filtersConfig={headerFilters}
          />
        </Stack>
      )}

      {!Array.isArray(allCurrentItems) && (
        <ListDataCommandBar
          cfg={allCurrentItems}
          _paginate={filters => {
            _paginate({
              limit: itemsPerPage ?? 10,
              ...debouncedTerms,
              ...selectedKeys,
              ...filters
            });
          }}
          isPaginateDisabled={isPaginateDisabled}
          hasFilters={!!businessUnitsSelector || hasFilters}
          resetSelectedItems={() => {
            setUrlParams({ pathname: location.pathname, params: {} });
            setSelectedKeys({});
            setSelectedTerms({});
            setVisibleTerms({});
          }}
          resetItemsPerPage={() => setItemsPerPage(undefined)}
          toggleFilterVisibility={toggleFilterVisibility}
        />
      )}

      {isFilterVisible && (
        <ListDataFilters
          setItemsPerPage={setItemsPerPage}
          setSelectedKeys={setSelectedKeys}
          setVisibleTerms={setVisibleTerms}
          setSelectedTerms={setSelectedTerms}
          filterCurrentItems={filterCurrentItems}
          toggleFilterVisibility={toggleFilterVisibility}
          items={items}
          filters={filters}
          _paginate={_paginate}
          itemsPerPage={itemsPerPage}
          visibleTerms={visibleTerms}
          selectedKeys={selectedKeys}
          selectedTerms={selectedTerms}
          debouncedTerms={debouncedTerms}
          isCloseFilterButtonDisabled={isCloseFilterButtonDisabled}
          isItemsPerPageFilterDisabled={isItemsPerPageFilterDisabled}
        />
      )}

      {massActionsType && (
        <ListDataFiltersMassAction
          setItemsPerPage={setItemsPerPage}
          setSelectedKeys={setSelectedKeys}
          setVisibleTerms={setVisibleTerms}
          setSelectedTerms={setSelectedTerms}
          filterCurrentItems={filterCurrentItems}
          toggleFilterVisibility={toggleOpenMassActionsFilterVisibility}
          items={items}
          filters={filters}
          _paginate={filters =>
            _paginate({ ...filters, ...bulkOptionsFilters })
          }
          itemsPerPage={itemsPerPage}
          visibleTerms={visibleTerms}
          selectedKeys={selectedKeys}
          selectedTerms={selectedTerms}
          debouncedTerms={debouncedTerms}
          isCloseFilterButtonDisabled={isCloseFilterButtonDisabled}
          isItemsPerPageFilterDisabled={isItemsPerPageFilterDisabled}
          selectedCounter={
            customSelection?.getSelectedCount() ?? selection.getSelectedCount()
          }
          massActionsType={massActionsType ?? null}
          sendMassActionsFunction={sendMassActionsFunction}
          selectedItemsIds={itemsSelected[0] && itemsSelected?.map(i => i.id)}
          itemsSelection={itemsSelected}
          setItemsSelected={setItemsSelected}
          openMassActionsFilter={openMassActionsFilter}
          setBulkOptionsFilters={setBulkOptionsFilters}
        />
      )}

      {hasScrollablePane ? (
        <div
          className={classNames.wrapperScrollable}
          style={{ height: scrollablePanelHeight }}
        >
          <ScrollablePane
            scrollbarVisibility={ScrollbarVisibility.auto}
            styles={{
              stickyAbove: listStyles.headerWrapper
            }}
          >
            <ShimmeredDetailsList
              setKey="items"
              items={
                Array.isArray(allCurrentItems)
                  ? allCurrentItems
                  : allCurrentItems?.items
              }
              columns={columns}
              selection={customSelection ?? selection}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              constrainMode={ConstrainMode.unconstrained}
              layoutMode={layoutMode}
              selectionMode={openMassActionsFilter ? 2 : selectionMode}
              selectionPreservedOnEmptyClick={selectionPreservedOnEmptyClick}
              enableShimmer={isLoadingList}
              skipViewportMeasures={skipViewportMeasures}
              detailsListStyles={listStyles}
              onRenderDetailsHeader={(props, defaultRender) => (
                <Sticky
                  stickyPosition={StickyPositionType.Header}
                  isScrollSynced
                >
                  {defaultRender({
                    ...props
                  })}
                </Sticky>
              )}
              onRenderRow={
                isRowSelectable
                  ? props => (
                      <DetailsRow {...props} data-selection-toggle="true" />
                    )
                  : null
              }
            />
          </ScrollablePane>
        </div>
      ) : (
        <ShimmeredDetailsList
          setKey="items"
          items={
            Array.isArray(allCurrentItems)
              ? allCurrentItems
              : allCurrentItems?.items
          }
          columns={columns}
          selection={customSelection ?? selection}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="Row checkbox"
          constrainMode={ConstrainMode.horizontalConstrained}
          layoutMode={layoutMode}
          selectionMode={openMassActionsFilter ? 2 : selectionMode}
          selectionPreservedOnEmptyClick={selectionPreservedOnEmptyClick}
          enableShimmer={isLoadingList}
          skipViewportMeasures={skipViewportMeasures}
          detailsListStyles={listStyles}
          onRenderRow={
            isRowSelectable
              ? props => <DetailsRow {...props} data-selection-toggle="true" />
              : null
          }
        />
      )}
      {!isLoadingList &&
        !Array.isArray(allCurrentItems) &&
        allCurrentItems?.meta?.totalItems === 0 && (
          <Stack
            styles={{
              root: {
                alignItems: "center"
              }
            }}
          >
            <img
              src={undrawNoData}
              title="Nenhum dado encontrado no banco"
              alt="Nenhum dado encontrado no banco"
              style={{ width: "55vh", height: "55vh" }}
            />
          </Stack>
        )}
    </Fragment>
  );
}

export default ListData;
